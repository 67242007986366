// 配置站点
const site_from_map = {
  us: ['rwus', 'rwmus', 'andrwus', 'iosrwus'],
  fr: ['rwfr', 'rwmfr', 'andrwfr', 'iosrwfr'],
  rw: ['rw', 'rwm', 'andrwother', 'iosrwother'],
  uk: ['rwuk', 'rwmuk', 'andrwgb', 'iosrwgb'],
  mx: ['rwmx', 'rwmmx', 'andrwmx', 'iosrwmx'],
  ca: ['rwca', 'rwmca', 'andrwca', 'iosrwca'],
}

const faqText = {
  'us': 'SHEIN_KEY_PC_18319',
  'fr':	'SHEIN_KEY_PC_18322',
  'uk': 'SHEIN_KEY_PC_18320',
  'gb': 'SHEIN_KEY_PC_18320',
  'ca': 'SHEIN_KEY_PC_18289',
  'mx': 'SHEIN_KEY_PC_18292',
  'rw': 'SHEIN_KEY_PC_18293',
}

const hostUrlList = {
  'main': `https://www.romwe.com/user/romwevip`,
  'other': `https://$1.romwe.com/user/romwevip`
}

const formatSiteFrom = site_from => {
  if (['rw', 'rwm'].includes(site_from) || site_from.includes('other')) { return 'rw' }
  if (['rwmx', 'rwmmx'].includes(site_from)) { return 'mx' }
  const reg = /^(rwm|rw|andrw|iosrw)(.+)/
  const result = site_from.match(reg)
  let tempSiteFrom = ''
  if (result) {
    let len = result.length - 1
    tempSiteFrom = result[len] && result[len].length >= 2 ? result[len] : site_from
  } else {
    tempSiteFrom = site_from
  }
  if (tempSiteFrom == 'gb') tempSiteFrom = 'uk'
  return tempSiteFrom
}

const RW_VIP_CONFIG = {
  formatSiteFrom,
  faqText,
  hostUrlList,
  showEntranceSites: Object.values(site_from_map).reduce((result, item) => result.concat(item), [])
}

export default RW_VIP_CONFIG
