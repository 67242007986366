/**
 * ADA弹窗模型指令
 * 
 * 例子：
 * v-ada-modal={ show: [boolean], first: [element,function]}
 * show:  控制弹窗是否展示的变量指,
 * first: 打开弹窗默认聚焦的元素，可以为函数自定义聚焦方法，可以为元素节点
 */
let IgnoreUtilFocusChanges = false
function attemptFocus (el) {
  if (!el) return
  IgnoreUtilFocusChanges = true
  try {
    if (typeof el === 'function') {
      el()
    } else {
      el.focus()
    }
  } catch(e) {
    // empty
  }
  IgnoreUtilFocusChanges = false
  return document.activeElement == el
}
class ADAModalDirective {
  $el = null // 当前dialog元素
  $roleEl = null
  firstFocusElment = null // 第一位聚焦元素
  lastFocusElment = null // 上一次聚焦的元素
  originFocusElement = null // 触发弹窗焦点元素

  vnode = null
  vparam = {} // 备份相对应传进来的参数

  constructor (el, binding, vnode) {
    let firstElment = el.firstElementChild
    if (firstElment.classList.contains('she-mask')) {
      firstElment = firstElment.nextElementSibling
    }

    const prevNode = document.createElement('div')
    prevNode.setAttribute('tabIndex', binding.value?.tabindex || 0)
    const lastNode = document.createElement('div')
    lastNode.setAttribute('tabIndex', binding.value?.tabindex || 0)

    el.insertBefore(prevNode, firstElment)
    el.appendChild(lastNode)

    firstElment.setAttribute('role', 'dialog')
    firstElment.setAttribute('aria-modal', 'true')

    this.$el = el
    this.$roleEl = firstElment
    this.vnode = vnode

    const { oldValue = {}, value = {} } = binding
    if (oldValue.show === undefined && value.show === true) {
      this.initParams(el, vnode, value)
      vnode.context?.$nextTick(() => { this.open() }) 
    }
  }

  initParams (el, vnode, param) {
    // 保存原始弹窗触发元素
    this.vparam = param
    this.$el = el
    this.$roleEl = this.getRoleElement(el.firstElementChild)
    this.vnode = vnode
    this.originFocusElement = document.activeElement
    this.addEventListeners()
  }

  getRoleElement (el) {
    if (!el) return ''
    if (el.getAttribute('role') === 'dialog') {
      return el
    }
    return this.getRoleElement(el.nextElementSibling)
  }

  open () {
    attemptFocus(this.getFirsFocusElement())
  }

  close () {   
    this.removeListeners()
    attemptFocus(this.originFocusElement)
    this.originFocusElement = null
  }

  focusFirstDescendant (element) {
    for (var i = 0; i < element.childNodes.length; i++) {
      var child = element.childNodes[i]
      if (attemptFocus(child) ||
          this.focusFirstDescendant(child)) {
        return true
      }
    }
    return false
  }

  getFirsFocusElement () {
    const { first } = this.vparam
    return first  || ''
  }

  trapFocus (e) {
    if (IgnoreUtilFocusChanges) return

    const target = e.target
    if (this.$roleEl.contains(target)) {
      this.lastFocusElment = target 
      return
    }
    
    return this.focusFirstDescendant(this.$roleEl) || attemptFocus(this.getFirsFocusElement())
  }

  addEventListeners () {
    this.$el.addEventListener('focus', this.trapFocus.bind(this), true)
  }

  removeListeners () {
    this.$el.removeEventListener('focus', this.trapFocus.bind(this), true)
  }
}

const AdaModalDirective = {
  mounted (el, binding, vnode) {
    binding.instance.adaModalInstance = new ADAModalDirective(el, binding, vnode)
  },
  updated (el, binding, vnode) {
    const { value, oldValue } = binding
    const { instance } = binding
    const { adaModalInstance } = instance    

    if (oldValue.show === value.show) return
    if (oldValue.show === false && value.show === true) {
      adaModalInstance.initParams(el, vnode, value)
      !value.transition && instance.$nextTick(() => { adaModalInstance.open() })
      return
    }

    if (oldValue.show === true && value.show === false) {
      instance.$nextTick(() => { adaModalInstance.close() })
      return
    }
  }
}

export default AdaModalDirective
