// 配置站点
const site_from_map = {
  us: ['us', 'mus', 'pwus', 'andshus', 'iosshus'],
  fr: ['fr', 'mfr', 'pwfr', 'andshfr', 'iosshfr'],
  de: ['de', 'mde', 'pwde', 'andshde', 'iosshde'],
  uk: ['uk', 'muk', 'pwuk', 'andshgb', 'iosshgb'],
  es: ['es', 'mes', 'pwes', 'andshes', 'iosshes'],
  in: ['in', 'min', 'pwin', 'andshin', 'iosshin'],
  ar: ['ar', 'mar', 'maren', 'pwar', 'pwaren', 'andshsa', 'iosshsa'],
  kw: ['andshkw', 'iosshkw'],
  om: ['andshom', 'iosshom'],
  ae: ['andshae', 'iosshae'],
  qa: ['andshqa', 'iosshqa'],
  bh: ['andshbh', 'iosshbh'],
}

// 归属站点跟当前站点不一致的情况跳转
const site_from_list = [
  {
    includesSite: ['us', 'mus', 'pwus', 'andshus', 'iosshus'],
    curSite: ['us'],
    linkSite: 'us'
  }, {
    includesSite: ['ar', 'mar', 'pwar', 'maren', 'pwaren', 'andshsa', 'andshae', 'andshqa', 'andshkw', 'andshbh', 'andshom', 'iosshsa', 'iosshae', 'iosshqa', 'iosshkw', 'iosshbh', 'iosshom'],
    curSite: ['ar'],
    linkSite: 'ar'
  }, {
    includesSite: ['uk', 'muk', 'pwuk', 'iosshgb', 'andshgb'],
    curSite: ['uk'],
    linkSite: 'uk'
  }, {
    includesSite: ['in', 'min', 'pwin', 'andshin', 'iosshin'],
    curSite: ['in'],
    linkSite: 'in'
  }, {
    includesSite: ['es', 'mes', 'pwes', 'andshes', 'iosshes'],
    curSite: ['es'],
    linkSite: 'es'
  }, {
    includesSite: ['fr', 'mfr', 'pwfr', 'andshfr', 'iosshfr'],
    curSite: ['fr'],
    linkSite: 'fr'
  }, {
    includesSite: ['de', 'mde', 'pwde', 'andshde', 'iosshde'],
    curSite: ['de'],
    linkSite: 'de'
  }
]

const formatExpiringDate = {
  ddmmyyyySites: ['ar', 'au', 'br', 'de', 'ru', 'fr', 'nl', 'ca', 'mx', 'th', 'es', 'il', 'it', 'in', 'uk', 'vn', 'cl', 'ma', 'eur', 'sg', 'eg', 'roe', 'euqs', 'co'],
  yyyymmddSites: ['se', 'tw', 'hk', 'jp']
}

const specialSite = {
  'main': ['www'],
  'special': ['uk', 'gb'],
  'endSite': ['vn', 'hk', 'mx'],
  'noComEnd': ['in', 'tw', 'se']
}

const hostUrl = {
  'main': `https://$1.shein.com/user/sheinvip`,
  'special': `https://www.shein.co.$1/user/sheinvip`,
  'endSite': `https://www.shein.com.$1/user/sheinvip`,
  'noComEnd': `https://www.shein.$1/user/sheinvip`
}

// 正常多语言展示
const faqText = {
  'us': 'SHEIN_KEY_PC_18319',
  'fr':	'SHEIN_KEY_PC_18322',
  'de': 'SHEIN_KEY_PC_18321',
  'in':	'SHEIN_KEY_PC_18329',
  'uk': 'SHEIN_KEY_PC_18320',
  'gb': 'SHEIN_KEY_PC_18320',
  'es': 'SHEIN_KEY_PC_17145',
  'ar': 'SHEIN_KEY_PC_18328',
  'sa': 'SHEIN_KEY_PC_18328',
  'kw': 'SHEIN_KEY_PC_18326',
  'om': 'SHEIN_KEY_PC_18325',
  'ae': 'SHEIN_KEY_PC_18323',
  'qa': 'SHEIN_KEY_PC_18324',
  'bh': 'SHEIN_KEY_PC_18327',
  'ca': 'SHEIN_KEY_PC_18289',
  'br': 'SHEIN_KEY_PC_18290',
  'cl': 'SHEIN_KEY_PC_18291',
  'mx': 'SHEIN_KEY_PC_18292',
  'it': 'SHEIN_KEY_PC_18306',
  'se': 'SHEIN_KEY_PC_18307',
  'nl': 'SHEIN_KEY_PC_18308',
  'eur': 'SHEIN_KEY_PC_18309',
  'asia': 'SHEIN_KEY_PC_18310',
  'au': 'SHEIN_KEY_PC_18294',
  'nz': 'SHEIN_KEY_PC_18295',
  'il': 'SHEIN_KEY_PC_18296',
  'pl': 'SHEIN_KEY_PC_18297',
  'hk': 'SHEIN_KEY_PC_18299',
  'tw': 'SHEIN_KEY_PC_18300',
  'ru': 'SHEIN_KEY_PC_18301',
  'th': 'SHEIN_KEY_PC_18302',
  'ph': 'SHEIN_KEY_PC_18303',
  'sg': 'SHEIN_KEY_PC_18304',
  'za': 'SHEIN_KEY_PC_18305',
  'www': 'SHEIN_KEY_PC_18293',
  'vn': 'SHEIN_KEY_PC_18298',
  'roe': 'SHEIN_KEY_PC_21251',
  'jp': 'SHEIN_KEY_PC_21764',
  'ch': 'SHEIN_KEY_PC_21765',
  'my': 'SHEIN_KEY_PC_21766',
  'pt': 'SHEIN_KEY_PC_21767',
  'co': 'SHEIN_KEY_PC_28807',
  'kr': 'SHEIN_KEY_PC_30679'
}

// 特殊站点不用多语言
const specialFaqText = {
  'euqs': {
    siteText: 'https://euqs.shein.com/',
    titleText: 'EUQS'
  }
}

const vipUpdateInfo = {
  10: ['ca', 'br', 'cl', 'mx', 'www', 'us', 'roe', 'co'],
  8: ['it', 'se', 'nl', 'eur', 'asia', 'fr', 'de', 'uk', 'es', 'gb', 'euqs'],
  6: ['au', 'nz', 'il', 'pl', 'vn', 'hk', 'tw', 'ru', 'th', 'ph', 'sg', 'za', 'in', 'ar']
}

const formatSiteFrom = site_from => {
  // 中东几个站web统一走ar
  let arSiteList = ['kw', 'om', 'ae', 'qa', 'bh', 'sa', 'aren']
  if (['www', 'm'].includes(site_from) || site_from?.includes('other')) { return 'www' }
  const reg = /^(m|pw|andsh|iossh)(.+)/
  const result = site_from.match(reg)
  let tempSiteFrom = ''
  if (result) {
    let len = result.length - 1
    tempSiteFrom = result[len] && result[len].length >= 2 ? result[len] : site_from
  } else {
    tempSiteFrom = site_from
  }
  if (tempSiteFrom == 'gb') tempSiteFrom = 'uk'
  if (arSiteList.includes(tempSiteFrom)) tempSiteFrom = 'ar'
  return tempSiteFrom
}

const isSixCountryInSa = (site, site_from) => {
  let tempSiteFrom = ''
  if (['ar'].includes(site)) {
    const reg = /^(andsh|iossh)(.+)/
    const result = site_from.match(reg)
    if (result) {
      let len = result.length - 1
      tempSiteFrom = result[len] && result[len].length >= 2 ? result[len] : site_from
    }
  }
  return tempSiteFrom || site
}

const VIP_CONFIG = {
  site_from_map,
  site_from_list,
  formatExpiringDate,
  specialSite,
  hostUrl,
  faqText,
  specialFaqText,
  vipUpdateInfo,
  formatSiteFrom,
  isSixCountryInSa,
  showEntranceSites: Object.values(site_from_map).reduce((result, item) => result.concat(item), [])
}

export default VIP_CONFIG
