<template>
  <span 
    v-if="showMedal && isIcon"
    :class="`sidebar-icon-${level}`"
  ></span>
  <img 
    v-else-if="showMedal"
    :src="IS_RW ? rwMedalUrl : medalUrl"
    :alt="alertText"
    :style="{height, cursor: 'pointer'}"
    @click="jumpToVip"
  />
</template>
<script>
import { getVipInfo } from 'public/src/pages/common/userInfo'
import VIP_CONFIG from '../user_shein_vip/config'
import RW_VIP_CONFIG from '../user_romwe_vip/config'
const { PUBLIC_CDN, GB_cssRight, host, IS_RW } = gbCommonInfo

export default {
  props: {
    size: {
      type: String,
      default: 'M',
    },
    alertText: {
      type: String,
      default: ''
    },
    isIcon: {
      type: Boolean,
      default: false
    }
  },
  emits: ['set-level'],
  data: function () {
    return {
      IS_RW,
      PUBLIC_CDN,
      GB_cssRight,
      level: '',
      site_from: '',
      isOldEsVip: '',
      showMedal: false,
    }
  },
  computed: {
    height () {
      let sizeMap = {
        S: IS_RW ? '15px' : '18px',
        M: '22px',
        L: '26px',
      }
      return sizeMap[this.size] ? sizeMap[this.size] : sizeMap.M
    },
    medalUrl () {
      let urlMap = [
        { es: '', others: '', },
        { es: '', others: '', },
        {
          es: `${PUBLIC_CDN}/she_dist/images/sheinvip/notSheinVip-11bcc8dd6a.png`,
          others: this.GB_cssRight ? `${PUBLIC_CDN}/she_dist/images/sheinvip/vipS0ar-90fbd8bf92.png` : `${PUBLIC_CDN}/she_dist/images/sheinvip/vipS0-93629f61e3.png`,
        },
        {
          es: '',
          others: this.GB_cssRight ? `${PUBLIC_CDN}/she_dist/images/sheinvip/vipS1ar-ba7fc7905e.png` : `${PUBLIC_CDN}/she_dist/images/sheinvip/vipS1-401b7bf19a.png`,
        },
        {
          es: this.GB_cssRight ? `${PUBLIC_CDN}/she_dist/images/sheinvip/sheinVipar-5fc5339d3b.png` : `${PUBLIC_CDN}/she_dist/images/sheinvip/sheinVip-e1913f2057.png`,
          others: this.GB_cssRight ? `${PUBLIC_CDN}/she_dist/images/sheinvip/vipS2ar-43a8cdd1f4.png` : `${PUBLIC_CDN}/she_dist/images/sheinvip/vipS2-61dd145351.png`,
        },
        {
          es: '',
          others: this.GB_cssRight ? `${PUBLIC_CDN}/she_dist/images/sheinvip/vipS3ar-08e9d8c295.png` : `${PUBLIC_CDN}/she_dist/images/sheinvip/vipS3-359f32b711.png`
        }
      ]

      return  this.isEsSite(this.site_from) && this.isOldEsVip ? urlMap[this.level]?.es : urlMap[this.level]?.others
    },
    rwMedalUrl () {
      let urlMap = {
        2: `${PUBLIC_CDN}/she_dist/rw_images/user/romwevip/R-logo-43698f0765.png`,
        3: `${PUBLIC_CDN}/she_dist/rw_images/user/romwevip/O-logo-0a17d744ec.png`,
        4: `${PUBLIC_CDN}/she_dist/rw_images/user/romwevip/M-logo-39588c0d62.png`,
        5: `${PUBLIC_CDN}/she_dist/rw_images/user/romwevip/WE-logo-22c7e90f45.png`
      }
      return urlMap[this.level]
    }
  },
  created () {
    this.init()
  },
  methods: {
    async init () {
      let res = await getVipInfo()
      if (res?.showVipFlag) {
        this.level = res?.level
        this.$emit('set-level', this.level)
        this.site_from = res?.site_from
        this.isOldEsVip = res?.isOldEsVip
        this.showEntry()
      } else {
        this.$emit('set-level', null)
      }
    },
    showEntry () {
      if (IS_RW && !RW_VIP_CONFIG.showEntranceSites.includes(this.site_from)) return
      const headerSheinVip = document.querySelector('.j-header-shein-vip')
      const sideBarSheinVip = document.querySelector('.j-sidebar-she-vip')
      if (headerSheinVip)
        headerSheinVip.classList.remove('she-hide')
      if (sideBarSheinVip)
        sideBarSheinVip.classList.remove('she-hide')
      this.showMedal = true
    },
    isEsSite (site) {
      return VIP_CONFIG.site_from_map.es.includes(site)
    },
    jumpToVip () {
      window.location.href = IS_RW ? `${host}/user/romwevip` : `${host}/user/sheinvip`
    }
  }
}
</script>
