import Fingerprint2 from 'fingerprintjs2'
import schttp from 'public/src/services/schttp'

export function InitFtoken (cb, notSendFtoken) {
  // 获取设备指纹token
  const options = {
    excludes: {
      // userAgent: true,
      // webdriver: true,
      // deviceMemory: true,
      // hardwareConcurrency: true,
      // timezone: true,
      // cpuClass: true,
      // doNotTrack: true,
      // plugins: true,
      // canvas: true,
      // webgl: true,
      // webglVendorAndRenderer: true,
      // touchSupport: true,
      // fonts: true,
      // fontsFlash: true,
      // audio: true,
      enumerateDevices: true,
      //language: true,
      //adBlock: true,
      //openDatabase: true,
    }
  }
  Fingerprint2.get(options, async function(components){
    let canvas = ''
    let webgl = ''
    let values = components.map(component => {
      if (component.key === 'canvas') canvas = component.value
      if (component.key === 'webgl') webgl = component.value
      return component.value
    })
    const ipInfo = await schttp({ url: '/api/common/ipInfo/get' })
    gbCommonInfo.ipHash = ipInfo.ipHash
    values.push({
      key: 'ip',
      value: gbCommonInfo.ipHash
    })

    let ftoken = Fingerprint2.x64hash128(values.join(''), 31)

    if( window.gbCommonInfo ){
      gbCommonInfo.ftoken = ftoken
      if (canvas instanceof Array) {
        canvas.some((v) => {
          const index = v.indexOf('base64,')
          if (index > -1) { canvas = v.slice(index + 87, index + 119); return true }
          return false
        })
      }
      if (webgl instanceof Array) {
        webgl.some((v) => {
          const index = v.indexOf('base64,')
          if (index > -1) { webgl = v.slice(index + 87, index + 119); return true }
          return false
        })
      }
      gbCommonInfo._loginCanvas = canvas
      gbCommonInfo._loginWebgl = webgl
      if (!notSendFtoken) {
        schttp({
          method: 'POST',
          url: '/api/user/auth/send_ftoken/update',
          data: { ftoken },
        })
      }
    }
    typeof cb === 'function' && cb(ftoken)
  })
}

export function InitFtokenPromise (notSendFtoken) {
  return new Promise((resolve) => {
    InitFtoken((ftoken) => {
      resolve(ftoken)
    }, notSendFtoken)
  })
}
